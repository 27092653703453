<template>
    <div class="todos-panel">
        <el-menu :default-active="statusNavActiveIndex" class="tatus-nav" mode="horizontal">
            <el-menu-item index="1"><router-link to="/user/todos/list/all">全部待办</router-link></el-menu-item>
            <el-menu-item index="2"><router-link to="/user/todos/list/enrolled">报名中</router-link></el-menu-item>
            <el-menu-item index="3"><router-link to="/user/todos/list/sprogress">评选中<span class="tonav-numb">({{sprogress}})</span></router-link></el-menu-item>
            <el-menu-item index="4"><router-link to="/user/todos/list/progress">执行中<span class="tonav-numb">({{progress}})</span></router-link></el-menu-item>
            <el-menu-item index="5"><router-link to="/user/todos/list/completed">已完成</router-link></el-menu-item>
            <el-menu-item index="6"><router-link to="/user/todos/list/apply">调解中<span class="tonav-numb">({{apply}})</span></router-link></el-menu-item>
            <el-menu-item index="7"><router-link to="/user/todos/list/end">已结束</router-link></el-menu-item>
        </el-menu>
        <div class="todos-bd">
            <router-view></router-view>
        </div>
         
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "todosList",
    data() {
        return {
             statusNavActiveIndex: "1", // 项目状态当前选择
             enrolled:0,
             sprogress:0,
             progress:0,
             completed:0,
             apply:0,
             end:0
        };
    },
    methods: {
        setActiveNavIndex() {
            var localhostUriString = window.location.href;
            var curItem = localhostUriString.split("/").slice(-1)[0];
            var _this = this;
            switch (curItem) {
                case "all":
                    _this.statusNavActiveIndex = "1";
                    break;
                case "enrolled":
                    _this.statusNavActiveIndex = "2";
                    break;
                case "sprogress":
                    _this.statusNavActiveIndex = "3";
                    break;
                case "progress":
                    _this.statusNavActiveIndex = "4";
                    break;
                case "completed":
                    _this.statusNavActiveIndex = "5";
                    break;
                case "apply":
                    _this.statusNavActiveIndex = "6";
                    break;
                case "end":
                    _this.statusNavActiveIndex = "7";
                    break;
                default:
                    _this.statusNavActiveIndex = "1";
            }
        },
        routerTo(params){
            this.$router.push('/employeeuc/todos/todosAll');

        },
        getTask() {
            var _this = this;
            this.post(
                    "/task/task/count",
                    {
                        tk:this.$route.query.tk?this.$route.query.tk:0
                    },
                    function(res) {
                        if(res.code == 200){
                            _this.enrolled = res.data.enrolling;
                            _this.sprogress = res.data.sprogress;
                            _this.progress = res.data.progress;
                            _this.completed = res.data.completed;
                            _this.apply = res.data.apply;
                            _this.end = res.data.end;
                        }else{
                            _this.$message.error('项目不存在');
                        }
                    }
            );
            },
    },
    created() {
        this.getTask();
    },
    mounted(){
        this.setActiveNavIndex();
    }
};
</script>
<style lang="less" scoped>
@import "../../../styles/todos.less";
</style>


